import SmoothScroll from 'smooth-scroll';

export default () => {
    const scroll = new SmoothScroll('a[href*="#"]', {
        // header: '.section-header',
        updateURL: false,
    });

    if (window.location.hash) {
        window.scroll(0, 0);
        scroll.animateScroll(document.querySelector(window.location.hash))
    }
};
