const imagesCtx = require.context('./images', false, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);

import './styles/app.scss';
import navigation from './scripts/_navigation';
import smoothScroll from './scripts/_smooth-scroll';

document.addEventListener('DOMContentLoaded', ()  => {
    navigation();
    smoothScroll();

    const teamElements = document.querySelectorAll('.team-element__title');
    if (teamElements.length) {
        import(/* webpackChunkName: "team" */'./scripts/_team').then(({ init }) => init(teamElements));
    }

    const widgets = document.querySelectorAll('.widget > input.text, .widget > textarea');
    if (widgets.length) {
        import(/* webpackChunkName: "forms" */'./scripts/_forms').then(({ init }) => init(widgets));
    }

    const newsModule = document.querySelector('.news-list > .row');
    if (newsModule) {
        import('tiny-slider/src/tiny-slider').then(({ tns }) => {
            import('tiny-slider/src/tiny-slider.scss');

            tns({
                container: newsModule,
                items: 1,
                slideBy: 'page',
                controls: false,
                navPosition: 'bottom',
                loop: false,
                responsive: {
                    960: {
                        items: 3,
                    },
                },
            });
        });
    }

    const galleries = document.querySelectorAll('.ce_gallery');
    if (galleries.length) {
        import('luminous-lightbox').then(({ LuminousGallery }) => {
            import('luminous-lightbox/dist/luminous-basic.css');

            galleries.forEach((el) => {
                new LuminousGallery(el.querySelectorAll('a'));
            });
        })
    }

}, false);
